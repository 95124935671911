<template>
    <v-row @click="activeView !== 'taskCenter' ? moveTo(event) : ''" class="set-border-bottom ma-0 px-2" :class="{ 'hand' : activeView !== 'taskCenter' }" v-if="event">
        <v-col md="8" class="pr-0">
            <div class="col-12 pa-0" v-if="(activeView === 'moveIn' || event.task_type == 'moveIn') && event.Transfer == null">
                <div class="list-title pb-1 d-flex align-center">
                    <hb-icon class="pr-2" color="#101318">mdi-account-box</hb-icon>
                    Pending Move-In
                </div>
                <p class="text-subtitle-2">Complete the move-in process.</p>
            </div>
            <div class="col-12 pa-0" v-else-if="(activeView === 'moveIn' || event.task_type == 'moveIn') && event.Transfer != null">
                <div class="list-title pb-1 d-flex align-center">
                    <hb-icon class="pr-2" color="#101318">mdi-account-box</hb-icon>
                    Pending Transfer
                </div>
                <p class="text-subtitle-2">Finalize the Transfer Process</p>
            </div>
            <div class="col-12 pa-0" v-else>
                <div class="list-title pb-1 list-title ellipse-text pt-1">
                    <hb-icon class="pr-2" color="#101318">{{getIcon(event.icon)}}</hb-icon>{{ event.slug === 'task' ? getDetails('name') : event.name }}
                </div>
                <p class="text-subtitle-2 ellipse-text">{{ event.slug === 'task' ? getDetails('details') : event.text }}</p>
            </div>
            <div class="d-flex align-center">
                <a class="font-weight-medium d-flex align-center hb-link-secondary" v-if="unit" @click.stop="viewEntity('unit', unit.id)">
                    <div class="pl-1 pr-1">
                        <HbUnitIcon v-if="unit" small :type="getUnitTypeLabel(unit)" color="#306FB6" />
                    </div>
                    <div class="font-weight-medium">
                        #{{ unit.number }}
                    </div>
                </a>
                <a class="font-weight-medium d-flex align-center hb-link-secondary" v-if="contact" @click.stop="viewEntity('contact', contact.id)" :class="{'ml-3' : unit }">
                    <div class="pr-1 pb-1"> 
                        <HbIcon v-if="contactIsBusiness" color="#306FB6" small mdi-code="mdi-domain" />
                        <HbIcon v-else color="#306FB6" small mdi-code="mdi-account" />
                    </div>
                    <div class="font-weight-medium" :class="{'ellipse-text-unset': event.slug != 'task'}">
                        {{contact.first}} {{contact.last}}
                    </div>
                </a>
            </div>
            <hb-btn class="download-btn" color="secondary" v-if="event.action && activeView == 'taskCenter' && todo.Event && todo.Event.upload_id" small @click.native.stop="downloadFile">Download</hb-btn>
        </v-col>
        <v-col md="4" class="text-right px-2" :class="{'d-flex justify-end align-end': (!event.timestamp || !event.urgency_timer) && activeView === 'taskCenter' && !eventTypeTimeStamp}" >
            <div v-if="(activeView === 'moveIn' || event.task_type == 'moveIn') && event.Transfer == null">
                <div :style="{color: timeColor}" class="follow-timer pb-4">
                    <span class="font-11px">Follow Up Timer</span><br/>
                    <b>{{ duration }}</b>
                </div>
                <hb-btn color="primary" v-if="activeView == 'taskCenter'" small @click="moveTo(event, 'moveIn')">Go to Move-In</hb-btn>
            </div>
            <div v-else-if="(activeView === 'moveIn' || event.task_type == 'moveIn') && event.Transfer != null">
                <div :style="{color: timeColor}" class="follow-timer pb-4">
                    <span class="font-11px">Follow Up Timer</span><br/>
                    <b>{{ duration }}</b>
                </div>
                <hb-btn color="primary" v-if="activeView == 'taskCenter'" small @click="moveTo(event, 'transfer')">Go to Transfer</hb-btn>
            </div>
            <div v-else class="text-right">
                
                <div :style="{color: timeColor}" class="follow-timer pb-4 d-flex" v-if="event.urgency_timer">
                    <span >Follow Up Timer</span><br/>
                    <span class="hb-default-font-size font-weight-medium">{{ duration }}</span>
                </div>
                <div class="move-out-date pb-6" v-if="eventTypeTimeStamp">
                    {{ getDate(eventTypeTimeStamp) }}
                    <hb-tooltip v-if="!overLockRemoveOverlock">
                        <template v-slot:item>
                            <hb-icon class="pl-1" style="position:relative;top:-1px;" pointer small color="secondary" @click="dismissTask">mdi-close</hb-icon>
                        </template>
                        <template v-slot:body>
                            Dismiss This Task
                        </template>
                    </hb-tooltip>
                </div>
                <div class="d-flex flex-row">
                    <hb-btn color="secondary" v-if="(!(event.action && activeView == 'taskCenter')) && todo.Event && todo.Event.upload_id" small @click.native.stop="downloadFile">Download</hb-btn>
                    <v-btn color="primary" small class="font-weight-regular" v-if="event.action && activeView == 'taskCenter'" @click.stop="group_id ? $emit('showList',{groupId: group_id, count: todo.count}) : moveTo(event)">
                        {{ group_id || btnText }}
                    </v-btn>
                </div>
            </div>
        </v-col>       
    </v-row>    
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import api from '../../../assets/api.js';
    export default {
        name: "ProfileTaskItem",
        props: [ 'event', 'contact', 'unit', 'index', 'activeView', 'group_id', 'lease_id', 'created_date', 'todo', 'fetchTasks'],
        data() {
            return {
                duration: '',
                interval: '',
                timeColor: ''
            }
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                eventTypes: 'profileTasksStore/eventTypes'
            }),
            btnText() {
                return this.event.btn_text !== 'See List' && this.event.name !== 'Move Out' ? this.event.btn_text : this.event.name === 'Move Out' ? 'Move-Out' : 'Follow-Up';
            },
            eventTypeTimeStamp(){
                if (this.$props.todo && this.$props.todo.Event && this.$props.todo.Event.created_at) {
                    return this.$props.todo.Event.created_at;
                } else if (this.$props.event.task_type === 'moveIn' && this.$props.event.created) {
                    return this.$props.event.created;
                }
                return ''
            },
            moveOutEvent(){
                return this.event.slug === 'move_out' && this.event.name === 'Move Out';
            },
            overLockRemoveOverlock(){
                return this.event.slug === 'overlock_space' || this.event.slug === 'lock_removal';
            },
            contactIsBusiness(){
                return this.$props.contact.rent_as_business;
            }
        },
        mounted() {
            if((this.event.urgency_timer || this.$props.event.task_type === 'moveIn') && this.eventTypeTimeStamp){
                this.interval = setInterval(() => this.calculateTimer(), 1000);
            }
        },
        methods: { 
            byType(task){
                let types = this.eventTypes.filter(type => {
                    //console.log('title', task.title, type.action)
                    if(type.action === task.title){
                        return type;
                    }
                });
                return types;
            },
            getDate(value){
                return moment(value).format('MMM DD, YYYY')
            },
            getIcon(value){
                let icon = '';
                if (value && value.includes('Google Icon:')) {
                    icon = value.replace('Google Icon:', '')
                }
                return icon;
            },
            async downloadFile() {
                const downloadedFile = await api.get(this, api.UPLOADS + `${this.$props.todo.Event.upload_id}/download`);
                const fileContent = downloadedFile.file;
                var buffer = Buffer.from(fileContent.Body.data);
                var blob = new Blob([buffer], { type: downloadedFile.mimetype });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = downloadedFile.name;
                link.click();
            },
            async moveTo(event = null, goto = ''){
                if (this.activeView == 'moveIn' || goto == 'moveIn') {
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === event.Unit.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: event.unit_id });
                    EventBus.$emit('new_lead');
                } else if(this.activeView == 'moveIn' || goto == 'transfer') {
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === event.Unit.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: event.unit_id });
                    let data = {
                        lease_id: event.Transfer.from_lease_id,
                        contact: {id: event.contact.id},
                        unit_id: event.unit_id,
                        transfer: event.Transfer,
                        property_id: event.Unit.property_id
                    };
                    EventBus.$emit('transfer', data);
                } else {
                    switch (event.slug) {
                        case 'new_lead'             :
                        case 'new_web_lead'         :
                        case 'cart_abandonment'     :
                        case 'coupon_clicks'        :
                        case 'reservation_failure'  :
                        case 'hotlead'              :
                        case 'lead_follow_up'       :
                        case 'new_reservation'      : 
                        case 'new_web_reservation'  :
                        case 'reservation_follow_up':
                            if (event.slug === 'new_web_lead' || event.slug === 'new_web_reservation'|| event.slug === 'new_reservation' || event.slug === 'new_lead' || event.slug === 'cart_abandonment' || event.slug === 'coupon_clicks' || event.slug === 'hotlead'  || event.slug === 'reservation_failure') {
                                let event = {
                                    id: this.$props.todo.id,
                                    time: this.$props.todo.Event.created_at
                                }
                                this.fetchData(event);
                            } else {
                                this.fetchData();
                            }
                            break;
                        case 'collection_calls':
                            let contactData = this.$props.contact;
                            let taskData = this.$props.todo;
                            contactData.lease_id = this.$props.lease_id;
                            let allData = { ...contactData, ...taskData };
                            EventBus.$emit('HB-Navigation:DelinquencyCenter', allData);
                            break;
                        case 'lock_removal':
                            await this.$store.dispatch('profileTasksStore/removeOverlock', { unit_id: this.$props.unit.id, lease_id: this.$props.lease_id });
                            this.fetchTasks(false);
                            EventBus.$emit('fetch_unit_gate_status');
                            break;
                        case 'overlock_space':
                            await this.$store.dispatch('profileTasksStore/overlockSpace', { unit_id: this.$props.unit.id, lease_id: this.$props.lease_id });
                            this.fetchTasks(false);
                            break;
                        case 'move_out':
                        case 'move_out_cancelled':
                        case 'move_out_confirmation':
                            let data = {
                                property_id: this.$props.unit.property_id,
                                lease_id: this.$props.lease_id,
                                contact: this.$props.contact,
                                unit_id:  this.$props.unit.id
                            };
                            EventBus.$emit('move_out', data);
                            break;
                        case 'review_rate_change_notification':
                        case 'approve_rate_change':
                            let rateData = {
                                rateChangeType: this.$props.todo.Event.Rate_Change.reviewed ? 'finalize-rate-change' : 'review-rate-change',
                                rateChangeItem : this.$props.todo.Event.Rate_Change,
                            }
                            EventBus.$emit('rate_change', rateData);
                            break;
                        case 'move_out':
                        case 'auction_day':
                        case 'auction_payment':
                        case 'cut_lock_schedule_auction':
                            EventBus.$emit('schedule_auction', this.todo.Event.Lease);
                            break;
                        default:
                            break;
                    }
                }

            },

            fetchData(event = null){

                api.get(this, api.CONTACTS + this.$props.contact.id).then(r => {
                    var contact = r.contact;
                    var data = {
                        contact_id: contact.id,
                        lead_first: contact.first,
                        lead_last: contact.last,
                        id: contact.Lead.id
                    }

                    if(contact.Lead){
                        data.lead_time = contact.Lead.created;
                    }

                    if(this.$props.unit && contact.Reservations){
                        contact.Reservations.forEach( reservation => {
                            if (reservation.Lease && reservation.Lease.Unit && reservation.Lease.Unit.id == this.$props.unit.id) {
                                data.expires = reservation.expires;
                                data.lease_id = reservation.Lease.id;
                                data.reservation_id = reservation.id;
                                data.reservation_time = reservation.created;
                                data.unit_id = reservation.Lease.Unit.id;
                            }
                        });
                    }
                    data.event = event;

                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                });
            },
            calculateTimer() {

                let startDate = moment.utc(this.eventTypeTimeStamp).local();
                let endDate = moment();

                let diffInMilliSeconds = Math.abs(endDate - startDate) / 1000;

                const days = Math.floor(diffInMilliSeconds / 86400);
                diffInMilliSeconds -= days * 86400;

                let hours = (Math.floor(diffInMilliSeconds / 3600) % 24)
                diffInMilliSeconds -= hours * 3600;

                const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
                diffInMilliSeconds -= minutes * 60;

                const seconds = Math.floor(diffInMilliSeconds) % 60;

                let difference = '';
                // if (days > 0) {
                //   difference += days.toString().padStart(2, '0') + ':'
                // }
                hours += 24 * days;
                difference += hours.toString().padStart(2, '0') + ':'
                difference += minutes.toString().padStart(2, '0') + ':'
                difference += seconds.toString().padStart(2, '0')

                if (hours >= 48) {
                    this.timeColor = '#FB4C4C';
                } else if (hours > 0 || minutes >= ( this.event.urgency_time_limit ? this.event.urgency_time_limit : 5 )) {
                    this.timeColor = '#ffa300';
                } else if (minutes <= (this.event.urgency_time_limit ? this.event.urgency_time_limit : 5) && minutes >= 0) {
                    this.timeColor = '#02AD0F';
                } else {
                    this.timeColor = '#101318';
                }

                this.duration = difference;

            },
            getDetails(type){
                let task = this.$props.todo;
                if (task && task.Event) {
                    if (type == 'name') {
                        return task.Event.title;
                    } else {
                        return task.details;
                    }
                }
            },
            viewEntity(entity, entity_id){
                let params = {};
                params = {
                    component: entity,
                    props: {}
                };
                params.props[entity + '_id'] = entity_id;
                this.$store.dispatch('navigationStore/openSlideOut', params);
            },
            async dismissTask(){
                await this.$store.dispatch('profileTasksStore/dismissTask', { todo: this.$props.todo });
                this.fetchTasks(false);
            }
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
    }
</script>

<style lang="scss" scoped>
    .list-title{
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #101318;
    }
    .list-title:first-of-type + p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #637381;
        margin-bottom: 0;
        padding-bottom: 5px;
    }
    .follow-timer{
        font-size: 11px;
        line-height: 16px;
        text-align: right;
    }
    .move-up-icon{
        position: relative;
        top: -3px;
    }
    .move-out-date{
        font-size: 12px;
        line-height: 16px;
        color: #637381;
    }
    .font-11px{
        font-size: 11px;
    }
    .ellipse-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ellipse-text:hover{
        white-space: unset;
        overflow: auto;
        display: flex;
        align-items: baseline !important;
        overflow-wrap: anywhere;
    }
    .ellipse-text-unset {
        max-width: 7.2vw;
        white-space: unset;
        overflow: auto;
        text-overflow: ellipsis;
    }
    .download-btn {
        float: right;
    }
</style>

<style>
.task-list-container .set-border-bottom{
    border-bottom: 1px solid #DFE3E8;
}
</style>