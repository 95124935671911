<template>
    <v-card class="pa-0 pt-0 mb-6 hb-elevation-medium hb-border-default" :class="{'mt-4' : $vuetify.breakpoint.width < 600 && $vuetify.breakpoint.width > 1460}">
        <v-card-text v-if="!loading && !delinquencies.length">
            <v-row class="mx-0">
                <v-col md="12" class="text-center">
                    <div class="no-task-message"><v-icon class="move-up-icon" size="30" color="#02AD0F">mdi-check</v-icon> Tenant is in good standing!</div>
                </v-col>
            </v-row>
        </v-card-text>
        
        <v-card-text v-else-if="loading">
            <v-row class="mx-0 py-3" >
                <v-col class="text-center">
                     <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
                </v-col>
            </v-row>
        </v-card-text>

        <div class="pa-0"  v-else-if="selected.id">  
            <v-container>
                <v-row dense>
                    <v-col>
                        <p class="mb-0 hb-font-header-3-medium font-weight-medium">Delinquency Timeline</p>
                    </v-col>
                    <v-col v-show="delinquencies.length > 1" cols="4">
                         <v-select
                            :items="delinquencies"
                            v-model="selected_delinquency"
                            item-value="id"
                            background-color="white"
                            outlined
                            dense
                            hide-details
                            :menu-props="{ bottom: true, offsetY: true }"
                        >
                            <template v-slot:selection="data">
                                <div class="hb-input-overflow d-flex align-center">
                                    <hb-unit-icon color="#101318" class="pr-1" :type="getUnitTypeLabel(data.item.Lease.Unit)" />
                                    {{data.item.Lease.Unit.number}}
                                </div>
                            </template>
                            <template v-slot:item="data">
                                <hb-unit-icon color="#101318" class="pr-1" :type="getUnitTypeLabel(data.item.Lease.Unit)" />
                                {{data.item.Lease.Unit.number}}
                            </template>
                        </v-select> 
                    </v-col>
                </v-row>
            </v-container>   
            <v-divider></v-divider>
            
            <v-timeline align-top dense>
                
                <v-timeline-item class="pb-5 timeline-item timeline-item-complete" color="white" small dense>
                    <template v-slot:icon>
                        <hb-icon color="primary" style="background-color: white;">mdi-check-circle</hb-icon>
                        <!-- <hb-icon  color="primary" style="padding: 3px 0; background-color: white;">mdi-check-circle</hb-icon> -->
                    </template>
                    <v-row no-gutters class="pa-0 pr-3">
                        <v-col cols="3">
                            <span class="hb-font-body-medium">Day {{start_stage.start }}</span><br />
                            <span class="hb-font-caption">{{selected.start_date | formatDate}}</span>
                        </v-col>
                        <v-col class="timeline-item">        
                            <v-expansion-panels dense flat >
                                <v-expansion-panel dense class="ma-0 pa-0" disabled readonly>
                                    <v-expansion-panel-header class="ma-0 pa-0"> 
                                        <v-list-item class="ma-0 pa-0" >
                                        <v-list-item-content class="ma-0 pa-0 pt-1">
                                            <p class="hb-font-body-medium mb-1">{{start_stage.name}}</p>
                                            <p class="hb-font-caption" style="line-height: 16px;">{{start_stage.description}}</p>
                                        </v-list-item-content>
                                        </v-list-item>
                                    </v-expansion-panel-header>            
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>

                    </v-row>
                </v-timeline-item>  
                
                <v-timeline-item 
                    v-for="(stage, index) in selected.Timeline"
                    :class="'timeline-item-'+stageStatus(stage)"
                    class="timeline-item"
                    :key="index"
                    color="white"
                    small
                    dense
                >   
                    <template v-slot:icon>
                        
                        <hb-icon  v-if="stageStatus(stage) === 'failed'" color="#FB4C4C" style=" background-color: white;">mdi-alert-octagon-outline</hb-icon>
                        <hb-icon  v-if="stageStatus(stage) === 'missed'" color="#FB4C4C" style=" background-color: white;" >mdi-alert-octagon-outline</hb-icon>
                        <hb-icon  v-if="stageStatus(stage) === 'complete'" color="primary" style=" background-color: white;">mdi-check-circle</hb-icon>
                        <hb-icon  v-if="stageStatus(stage) === 'current'" color="primary" style=" background-color: white;">mdi-record-circle-outline</hb-icon>
                        <hb-icon  v-if="stageStatus(stage) === 'paused'" color="primary" style=" background-color: white;">mdi-record-circle-outline</hb-icon>
                        
                        <hb-icon   v-if="stageStatus(stage) === 'future'" color="#c4cdd5" style=" background-color: white;">mdi-circle-outline</hb-icon>
 
                    </template>

                    <v-row no-gutters class="pr-3">
                  
                        <v-col cols="3">
                            <span class="hb-font-body-medium">Day {{stage.start + 1}}</span><br />
                            <span v-if="isActiveAction(stage)"  class="hb-font-caption">{{stage.execution_date | formatDate}}</span>
                        </v-col>
                        <v-col class="timeline-item">        
                            <v-expansion-panels dense flat >
                                <v-expansion-panel dense class="ma-0 pa-0" :disabled="stage.type === 'pause' || stage.type === 'resume'" :readonly="stage.type === 'pause' || stage.type === 'resume'" >
                                    <v-expansion-panel-header class="ma-0 pa-0"> 
                                        <v-list-item class="ma-0 pa-0" >
                                        <v-list-item-content class="ma-0 pa-0 pt-1">
                                            <p class="hb-font-body-medium mt-0 mb-1">{{stage.name}}</p>
                                            <p class="hb-font-caption" style="line-height: 16px;">{{stage.description}}</p>
                                            <p class="hb-font-caption my-0 pa-0" >
                                                <hb-icon v-if="stageStatus(stage) === 'failed'" class="mr-1" small color="#FB4C4C">mdi-alert</hb-icon>
                                                <span v-if="stage.type === 'action'" style="line-height: 36px;" class="hb-font-caption" :class="{'timeline-error-msg': stageStatus(stage) === 'failed' }">Actions ({{stage.Actions.length}})</span>
                                                <span v-else-if="stage.type === 'pause'" style="line-height: 36px" class="hb-font-caption"> Paused By {{stage.by}} </span>
                                                <span v-else-if="stage.type === 'resume'" style="line-height: 36px" class="hb-font-caption"> Resumed By {{stage.by}} </span>
                                            </p>
                                        </v-list-item-content>
                                        </v-list-item>

                                    </v-expansion-panel-header>
                                     <!-- <v-expansion-panel-content class="ma-0 pa-0"  v-if="stage.type === 'pause'">
                                          <span style="line-height: 36px" class="hb-font-caption">Paused By {{stage.paused_by}}</span>
                                     </v-expansion-panel-content> -->


                                    <v-expansion-panel-content class="ma-0 pa-0"  v-if="stage.Actions && stage.type === 'action'">
                                        Delinquency actions for this stage:
                                        <template v-for="(a, i) in stage.Actions" >
                                            
                                            <div :key="i" class="timeline-action-description py-3 px-4 mb-3 mt-2" :class="{'timeline-error': a.error }">
                                                
                                                
                                                <div class="pb-1"><strong>Action:</strong></div>
                                            
                                                <div v-if="a.error" class="timeline-error-msg hb-font-caption-medium">
                                                    <hb-icon small color="#FB4C4C">mdi-alert</hb-icon> Error: {{a.error}}
                                                </div>
                                                <div>{{ a.action_name }}</div>
                                            
                                                <div v-if="a.action === 'lease_standing'">{{a.description}}</div>
                                                <div v-if="a.action === 'task'">
                                                    <div>{{a.description}}</div>
                                                    <!-- <div>{{a.Task.details}}</div>  -->
                                                </div>
                                                    <div v-if="a.action === 'document'">
                                                    
                                                    <div v-if="a.Document && a.Document.include_alternate" class="hb-font-caption"><hb-icon small>mdi-check</hb-icon> Include Alternate Contact</div>
                                                    <div v-if="a.Document && a.Document.include_lien" class="hb-font-caption"><hb-icon small>mdi-check</hb-icon> Include Lien Holder</div>
                                                
                                                    <div>{{a.description}}</div>
                                                    <v-card v-for="dm in a.DeliveryMethods" :key="dm.id" class="py-3 px-2 my-2 delivery-method" flat >
                                                        <hb-icon  class="mr-1" color="success" small v-if="dm.completed && !dm.error" >mdi-check-circle</hb-icon>
                                                        <strong>Delivery Method:</strong> <span style="text-transform: capitalize;">{{ dm.name}} to {{ dm.sent_to }}</span>
                                                        <template v-if="dm.error">
                                                        <div v-for="(e, i) in dm.error.split(' | ')" :key="i" class="delivery-method-error hb-font-caption-medium">
                                                            <hb-icon color="#FB4C4C" small v-if="dm.completed && dm.error" >mdi-alert</hb-icon> {{e}}
                                                        </div>
                                                        </template>
                                                    </v-card>
                                                </div>
                                                <div v-if="a.action === 'message'">
                                                
                                                    <div v-show="a.Message && a.Message.include_alternate" class="hb-font-caption"><hb-icon small>mdi-check</hb-icon> Include Alternate Contact</div>
                                                    <div v-show="a.Message && a.Message.include_lien" class="hb-font-caption"><hb-icon small>mdi-check</hb-icon> Include Lien Holder</div>
                                                
                                                    <v-card v-for="dm in a.DeliveryMethods" :key="dm.id" class="py-3 px-2 my-2 delivery-method" flat >
                                                        <hb-icon class="mr-1" color="success" small v-if="dm.completed && !dm.error" >mdi-check-circle</hb-icon>
                                                        <strong>Delivery Method:</strong> <span style="text-transform: capitalize;">{{ dm.name }} to {{ dm.sent_to }}</span>
                                                        <template v-if="dm.error">
                                                            <div v-for="(e, i) in dm.error.split(' | ')" :key="i" class="delivery-method-error hb-font-caption-medium">
                                                                <hb-icon color="#FB4C4C" small v-if="dm.completed && dm.error" >mdi-alert</hb-icon> {{e}}
                                                            </div>
                                                        </template>
                                                    </v-card>
                                                </div> 
                                               
                                                <div v-if="a.action === 'fee'">{{a.description}}</div>
                                                
                                            </div>
                                        </template>
                                    </v-expansion-panel-content> 
                                </v-expansion-panel>
                            </v-expansion-panels>




                            <!-- <span class="hb-font-body-medium">{{stage.name}}</span><br />
                            <span class="hb-font-caption">{{stage.description}}</span><br /> -->
                            
                            <!-- <hb-tooltip v-if="stage.Actions">
                                <template v-slot:item >
                                    <span style="line-height: 36px;  cursor: pointer" class="hb-font-caption actions-link">Actions ({{stage.Actions.length}})</span>
                                </template>
                            
                                <template v-slot:body>
                                    
                                </template>
                            </hb-tooltip>  -->
                        </v-col>
                    
                    </v-row> 
                </v-timeline-item>
            </v-timeline>
        </div>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
export default {
    name: "ContactDelinquency",
    components: {  },
    props: ['contact', 'propertyView', 'active_delinquencies'],
    data() {
        return {
            delinquencies: [],
            
            selected_delinquency:'',
            loading: false,
            start_stage:  {
                date: "",
                name: 'Tenant Rent Assessment',
                description: 'This is the day the tenant\'s rent is due',
                start: 1
            }
        }
    },
    created() {
        
       if(!this.active_delinquencies){
           this.getDelinquencies();
       } else {
           this.delinquencies = this.active_delinquencies; 
           if(this.delinquencies.length){
               this.selected_delinquency = this.delinquencies[0].id
            }
            this.loading = false;
       }
    },
    filters: {
       delivery_method(method){
            return method && method.replaceAll('_', ' ').replaceAll('sms', "SMS"); 
       }, 
        timeline_item_icon(item){
            // return "mdi-circle-medium"
            return "mdi-record-circle";
        },
        
        timeline_item_icon_color(item){
            return "primary"
        }, 
         timeline_item_icon_background(item){
            return "white"
        }
    }, 
    computed: {
        pause_date(){
           for (let i = 0; i < this.selected.Timeline.length; i++) {
                let item = this.selected.Timeline[i];
                if(item.type === 'pause' && !item.end_date) return item.execution_date;
            }
            return null; 
        }, 
        selected(){ 
            let active = {};
            if(this.delinquencies.length){
                active = this.delinquencies.find(d => d.id === this.selected_delinquency);
            }
            return active; 
        },
        active_stage(){
            // return the first step in the future
            for (let i = 0; i < this.selected.Timeline.length; i++) {
                 let item = this.selected.Timeline[i];
                if(item.type === 'pause' && !item.end_date) return item;
                if(item.type === 'action' && item.execution_date >= moment().format('YYYY-MM-DD') && item.Actions.find(a => !a.completed) ){
                    return item;
                }
            }
        }, 
        current_timeline_date(){
            return this.pause_date ? this.pause_date : moment().format('YYYY-MM-DD')
        },
        is_deactivated(){
            this.stageStatus(this.active_stage) === 'paused'
        }
    },
    methods: {
        unitNumer(delinquency){
            return delinquency.Lease.Unit.number
        }, 
        isActiveAction(stage){
            let stageStatus = this.stageStatus(stage);
            if(this.selected.status === 'paused' &&  (stageStatus === 'future' || stageStatus === 'current')){
                return false;
            } 
            return true;


        }, 
        stageStatus(stage){

            if(stage.type === 'pause' && !stage.end_date ) return 'paused'; 
            if(stage.type === 'pause' && stage.end_date ) return 'complete';

            if(stage.type === 'resume' ) return 'complete'; 

            if(
                    stage.Actions && 
                    stage.Actions.find(a => a.error || (a.DeliveryMethods && a.DeliveryMethods.find(dm => dm.error)))  
            ) {
                return 'failed'; 
            }

            if(stage.Actions &&  !stage.Actions.find(a => !a.completed) ) return 'complete'; 
            
            if(stage.execution_date < this.current_timeline_date){    
                if(stage.Actions.find(a => !a.completed)) return "missed"
                return "complete"; 
            } 
            // else if(stage.execution_date === this.active_stage.execution_date ) {
            //     return "current"
            // } 
            else {
                return "future"
            }
        }, 
        async getDelinquencies(){  
            this.loading = true;
            let r = await api.get(this, api.CONTACTS + this.contact.id + '/delinquencies')
            this.delinquencies = r.delinquencies;
            this.selected_delinquency = this.delinquencies[0].id
            this.loading = false;
             
        },
    },
    watch: {
        '$route.params.contact_id'(){
            this.getDelinquencies();
        },
        propertyView(){
            this.getDelinquencies();
        },
        active_delinquencies(delinquencies){
            this.delinquencies = delinquencies; 
            if(this.delinquencies.length){
                this.selected_delinquency = this.delinquencies[0].id
            }
        }
    }
}
</script>

<style lang="scss" scoped >
    .actions-link{
        border-bottom: 1px dashed #ccc;
        padding-bottom: 3px;
        cursor: pointer;
    }
    .timeline-error-msg.actions-link{
        border-bottom: 1px dashed #FB4C4C;
        padding-bottom: 3px;
    }
    
    .v-timeline-item{
        margin-bottom: 0;
    }
    .v-timeline-item.timeline-item.theme--light.timeline-item-paused,
    .v-timeline-item.timeline-item.theme--light.timeline-item-paused .hb-font-caption, 
    .v-timeline-item.timeline-item.theme--light.timeline-item-paused .v-list-item__content{
        color: #FB4C4C;
    }
    .hb-font-caption{
        color: #637381;

    }
    .v-timeline-item.timeline-item.theme--light.timeline-item-future,
    .v-timeline-item.timeline-item.theme--light.timeline-item-future .hb-font-caption, 
    .v-timeline-item.timeline-item.theme--light.timeline-item-future .v-list-item__content{
        color: #c4cdd5;
    }
    .timeline-action-description{
        border: 1px solid rgba(0, 0, 0, 0.12); 
        border-radius: 4px;
    }
    .timeline-error{
        border: 1px solid #FB4C4C; 
    }
    .timeline-error-msg{
        color: #FB4C4C; 
    }

    .delivery-method{
        border: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #f9fafb; 
    }

    .no-task-message{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
    .delivery-method-error{
        color: #FB4C4C; 
        
    }
    
</style>
